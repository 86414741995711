import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import {Container, Row, Col,Card,Button} from 'react-bootstrap'
import {BrowserView, MobileView} from 'react-device-detect';

// const IndexPage = () => (
class IndexPage extends React.Component {
  render(){
  return(
  <>
  <Layout>
    <SEO title="Home" />
    
      <MobileView>
        <div className="homeAuto">
            <Image alt="homeimage" filename="indeximage.png"  className="imgIndex"/>
            <div className="mechanicalImage">
                <h1 style={{fontWeight:"bold"}} className="index1">BEYOND</h1>
                <h3 className="index2">THE POSSIBILITIES</h3>
                <p style={{fontSize:"20px"}} className="index3">WE DELIVER YOUR VISION SO YOU CAN DELIVER SUCCESS.</p>
            </div>
        </div>  
      </MobileView>    
     <BrowserView>
      <div className="indexVideo homeAuto">
        <video style={{ width: `100%`, opacity:"0.5" }} loop="loop" autoPlay="autoPlay" >
          <source src="./images/stock2.mp4" type="video/mp4" />
          <track src="" kind="captions" srclang="en" />
        </video>
        <div className="indexImage">
          <h1 style={{fontSize:"70px", textAlign:"center"}}>BEYOND</h1>
          <h1 className="videoHeader" >THE POSSIBILITIES</h1>
          <h4 style={{fontWeight:"bold",color:"#ffffff"}}>WE DELIVER YOUR VISION SO YOU CAN DELIVER SUCCESS.</h4>
        </div>
      </div>
      </BrowserView>
    
      
      <Container >
        <Row className="homeCardrow1">
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="hardware" filename="hardware.png" />
              <Card.Body>
                <Button className="cardBtn" href="/hardware">Hardware</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="hardware" filename="top1.png" />
              <Card.Body>
                <Button className="cardBtn" href="/home-automation">Home Automation</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="hardware" filename="software_1.png" />
              <Card.Body>
                <Button className="cardBtn" href="/software">Software</Button>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>

        <Row className="IndexRow">
         <h3 className="homeCardrow2">Trace Width is answering the call to the technological solutions of tomorrow.</h3>
         <p className="homePara">Headquartered in Tampa, Florida, Trace Width is a premier engineering design and technology development company. We engineer your vision with an unparalleled balance of cost, quality, and time to market. We will analyse your prerequisites and work closely with you to ensure further project development at an affordable price to make your vision a successful reality.</p>
        </Row>
        <hr/>
        <Row>
          <h3 className="homeCardrow3">Some of the many industries we service:</h3>
        </Row>

        <Row className="homeCardrow1">
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="military" filename="military.png" />
              <div className="card-img-overlay" ><a href="/military" className="alink" >MILITARY</a> </div>
            </Card>
          </Col>
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="automotive" filename="automotive-1.png" />
              <div className="card-img-overlay"><a href="/automotive" className="alink">AUTOMOTIVE</a></div>
            </Card>
          </Col>
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="aerospace" filename="aerospace.png" />
              <div className="card-img-overlay"><a href="/aerospace" className="alink">AEROSPACE</a></div>
            </Card>
          </Col>
        </Row>
        <Row className="homeCardrow1">
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="medical" filename="medical.png" />
              <div className="card-img-overlay"><a href="/medical" className="alink">MEDICAL</a></div>
            </Card>
          </Col>
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="lab" filename="laboratory.png" />
              <div className="card-img-overlay"><a href="/laboratory" className="alink">LABORATORY</a></div>
            </Card>
          </Col>
          <Col sm={12} lg={4}>
            <Card className="cardBody">
              <Image alt="manufacturing" filename="manufacturing.png" />  
              <div className="card-img-overlay"><a href="/manufacturing" className="alink">MANUFACTURING</a></div> 
            </Card>
          </Col>
        </Row>

        
        <Row>
          <h3 className="homeCardrow4">Our Proven Process</h3>
        </Row>
        <Row className="ssss">
          <Col sm={12} lg={3} className="headerCol">
            <Image alt="requirement" filename="requirements.png" style={{width: '100px', left:'70px'}}  />
            <div className="headerSpan">Analyze Requirements</div>
            <div className="subHeading1">Your Design Needs</div>
          </Col>
          <Col sm={12} lg={3} className="headerCol" >
            <Image alt="develop" filename="develop.png" style={{width: '100px', left:'70px'}} />
            <div className="headerSpan">Create a Plan</div>
            <div className="subHeading1">Strategic Design Process</div>
        
          </Col>
          <Col sm={12} lg={3} className="headerCol">
            <Image alt="testing" filename="testing.png" style={{width: '100px', left:'70px'}}/>
            <div className="headerSpan">Dynamic Execution</div> 
            <div className="subHeading1">Expert Attention to Detail</div>
        
          </Col>
          <Col sm={12} lg={3} className="headerCol">
            <Image alt="product" filename="product.png" style={{width: '100px', left:'70px'}}/>
            <div className="headerSpan">Success Delivered</div> 
            <div className="subHeading1">Your Product, Your Way  </div>
        
          </Col>
        </Row>
      </Container>
      <div className="headerForm"> 
          <br/>
          <div className="homeCardrow5">Trace Width is the source for professional technological and engineering solutions</div>
          <div className="homeCardrow6">Start the conversation with us today!</div>
          <ContactForm  />
      </div>
    </Layout>
    </>
   )}
  }

export default IndexPage
